import React, { useContext } from "react"
import BannerContext from "../lib/context"

import Helmet from "react-helmet"

import Layout from '../components/layout'

import Header from "../components/Header"
import { useLocalData } from "../lib/hooks"
import Section, { TextSection, BackgroundSection, HalfCardSection } from '../components/Section'
import { getStaticPageData } from "../lib/API"
import BannerSection from "../components/BannerSection"

import ActivitiesImage from '../assets/images/home/activities.jpg'
import YouthBand from '../assets/images/stock-photos/youth-band.png'
import PrayerMeetingImage from '../assets/images/home/life-group.png'
import ChildrenImage from '../assets/images/home/children.jpg'
// import YoungAdultImage from '../assets/images/home/young-adults.jpg'


import YoungAdultImage from '../assets/images/groups/Youth-Groups.jpg'

import Fireplace from '../assets/images/church/mens-group.jpg'
import Emerge from '../assets/images/church/womens-dinner.jpg'

import Globe from '../assets/images/core/mission.png'
import ElderlyImage from '../assets/images/home/cafe.jpg'
import LunchImage from '../assets/images/home/monday-lunch.png'
// import PrayerMeetingI from '../assets/images/home/monday-lunch.png'

// import LifeImage from '../assets/images/home/life-groups2.jpg'
import LifeImage from '../assets/images/groups/Homegroups.jpg'





const Homepage = () => {

    // const [ headerData, setHeaderData ] = useState( [ ] )
    const siteTitle = "Blackheath and Charlton Baptist Church - Church Life"
    // const pageData = useLocalData( getStaticPageData, 'church-life-banners', 'church-life-banners' )
    const pageData = useContext( BannerContext )[ 'church-life-banners' ]
    const { header, banners } = pageData || { header: null, banners: null }
    const headerImages = header ? header.images : []
    const headerTitle = header ? header.title : 'Church Life'
    // const headerImages = useData( getEventsHeader )
    // const headerTitle = "Church Life"
    const headerIcon = <span className="icon major"><i className="fas fa-heartbeat"></i></span>

    return (
        
        <div id='church-life-page'>
            <Header images={headerImages} headerTitle={headerTitle} tint={0.25} />
            <Helmet title={siteTitle} />

            <BackgroundSection 
                id="life-groups" 
                title="Life Groups"
                description="Our Life Groups meet at various times throughout the week, providing a smaller, more intimate environment for individuals to share, disciple and grow together in the Word, prayer and fellowship.
                Members support and care for one another through life's celebrations, surprises and challenges. 
                We encourage everyone in our church family to be join a Life Group to build deeper relationships and walk alongside each other on our faith journey."
                buttons={[ { url: "/lifegroups", text: "Join Group",  style: 'transparent-button' } ]}
                background={[ LifeImage ]}
                tint={0.4}
                blur={0.2}
            />


        {/* <div className="grid-wrapper" style={{width: '100%', height: '100%', gridGap: '0 0'}}>
            <HalfCardSection
                title="Men's Ministry - The Fireplace"
                image={Fireplace}
            >
                <p></p><br/>
                <h4><b></b></h4>
                <p>Men's ministry description</p>
            </HalfCardSection>

            <HalfCardSection
                title="Women's Ministry - Emerge"
                image={Emerge}
                left={false}
            >
                <p> </p><br/>
                <h4><b></b></h4>
                <p>Women's ministry description</p>
            </HalfCardSection>
        </div> */}
{/* 
            <BackgroundSection 
                id="mens-ministry" 
                title="Men's Ministry - The Fireplace"
                description="Men's ministry description."
                // buttons={[ { url: "/lifegroups", text: "Join Group",  style: 'transparent-button' } ]}
                background={[ Fireplace ]}
                tint={0.3}
                blur={0.02}
                alt
            />

            <BackgroundSection 
                id="womens-ministry" 
                title="Women's Ministry - Emerge"
                description="Women's ministry description."
                // buttons={[ { url: "/lifegroups", text: "Join Group",  style: 'transparent-button' } ]}
                background={[ Emerge ]}
                tint={0.3}
                blur={0.1}
            /> */}

            <TextSection 
                id="prayer-meetings" 
                title="Prayer Meetings"
                description={`
                    <b>We have Morning Prayer Meetings Monday - Friday 6:00am  </b>

                    Join us in our Wednesday prayer and Fasting services, where we break the fast with a communion service at 6:00 pm

                    <small>"And let us consider how we may spur one another on toward love and good deeds, not giving up meeting together, as some are in the habit of doing, but encouraging one another—and all the more as you see the Day approaching."   
                            Hebrews 10:24-25 NIV"</small>`}
                // buttons={[ { url: "/prayer-meetings", text: "Read More" } ]}
                // background={[ PrayerMeetingImage ]}   
                tint={0.35}
                extraCSS={{backgroundColor: 'rgba(0, 55, 100, 0.1)'}}

            />
{/* 
            <BackgroundSection 
                id="over-50s-monday-lunch" 
                title="Over 50's - Monthly Monday Lunch"
                style="style1"
                buttons={[ { url: "/contact", text: "Contact Us", style: 'transparent-button' } ]}
                background={[ LunchImage ]}
                description={`These are held at 12:30pm on the second Sunday of every month. We offer an opportunity for people to fellowship together, get to know each other, sing together, talk to each other, and also ask any questions they have about the Christian faith.
                Please contact us on 020 8856 8654 for more information if you would like to know more.`
                }
                alt
                opacity={0.7}
                tint={0.45}
            />

            <BackgroundSection 
                id="over-50s-corner-stop-cafe" 
                title="Corner Stop Cafe"
                style="style1"
                buttons={[ { url: "/contact", text: "Contact Us", style: 'transparent-button' } ]}
                background={[ ElderlyImage ]}
                description={`Our café is open from 9:30am-12:30pm on Tuesdays, Wednesdays and Thursdays. We welcome anyone passing by, or planning to come, whether from our community or church family. Come for a friendly chat and sample our snacks and sandwiches.
                
                Our café food is very reasonably priced to make it affordable for everyone in our community. We serve teas, coffees, soup and hot chocolate, as well as cold drinks and bacon, sausage or cheese butties. Come, too, if you would just like a chat, even if you do not wish to buy our goodies.
                Looking forward to welcoming you for a friendly chat.`


                }
                alt
                tint={0.4}
            /> */}

            {/* <BackgroundSection 
                id="over-50s-corner-stop-cafe" 
                title="Over 50's - Corner Stop Cafe"
                style="style2"
                buttons={[ { url: "/contact", text: "Contact Us" } ]}
                background={[ ElderlyImage ]}
                description={`We offer a range of activities for members over 50 within our community. These include:

                <strong>Monthly Monday Lunch</strong>
                These are held at 12:30pm on the second Sunday of every month. We offer an opportunity for people to fellowship together, get to know each other, sing together, talk to each other, and also ask any questions they have about the Christian faith.
                Please contact us on 020 8856 8654 for more information if you would like to know more.

                <strong>Corner Stop Café</strong>
                Our café is open from 9:30am-12:30pm on Tuesdays, Wednesdays and Thursdays. We welcome anyone passing by, or planning to come, whether from our community or church family. Come for a friendly chat and sample our snacks and sandwiches.
                Our café food is very reasonably priced to make it affordable for everyone in our community. We serve teas, coffees, soup and hot chocolate, as well as cold drinks. Come, too, if you would just like a chat, even if you do not wish to buy our goodies.
                Looking forward to welcoming you for a friendly chat.`


                }
                tint={0.85}
            />  */}


            {/* <BackgroundSection 
                id="childrens-church" 
                title="Creche and Sunday School"
                description={`Our Children’s Church ministry offers children in our church family the opportunity to learn about Christ and scriptures, through practical and interactive engagements and conversations. 
                We believe that creative learning using a variety of resources that are relevant to our children’s daily lives enhances their ability to learn about God and engage with scripture.
                We have a number of activities that also take place during the year, which enable our children to engage with the wider church family using specific areas of interest
                 to them. Our children’s ministry leaders have all undergone safeguarding training and endeavour to the best of their ability 
                 to always put the children’s safety and welfare first.`}
                style="style2"
                // buttons={[ { url: "/childrens-church", text: "Read More" } ]}
                background={[ChildrenImage]}
                alt
                tint={0.25}
            /> */}

            <BackgroundSection 
                id="young-adults"
                title="Young Adults Ministry"
                description={ `Our Young Adults’ Ministry serves those aged 18 to 35. 
                    The aim of this ministry is to disciple, nurture and equip our young adults to become fruitful Christians whose lives bear witness to the testimony of Jesus Christ. 
                    We encourage and nurture them to live lives that demonstrate the fruits of the Holy Spirit.  
  
By grounding them in scripture, our young adults are pastored and equipped to live as intelligent, diligent and successful members and leaders of society.`}
                style="style1"
                background={[ YoungAdultImage ]}
                tint={0.4}
                blur={0.08}
            />

            <BackgroundSection 
                id="emerge"
                title="Emerge (Equipped to Thrive)"
                description={ `Emerge Ministry is our vibrant women’s group committed to supporting one another through continuous prayer partnerships. 
                    We foster spiritual growth and community through various events throughout the year, including conferences, retreats, themed dinners, lunches, and breakfasts. 
                    Join us as we nurture our faith, build lasting friendships, and celebrate together in fellowship`}
                style="style1"
                background={[ Emerge ]}
                tint={0.2}
                blur={0.1}
                alt
            />

            <BackgroundSection 
                id="fireplace-ministry"
                title="Fireplace Ministry"
                description={ `The Fireplace Ministry is a dedicated men’s group that meets regularly for prayer, fellowship, and engaging activities such as golf.
                    In addition to spiritual growth and camaraderie, the ministry actively serves the church by helping maintain the grounds and ensuring the space remains welcoming for all.`}
                style="style1"
                background={[ Fireplace ]}
                tint={0.4}
                blur={0.1}
            />

            {/* <div className="grid-wrapper" style={{width: '100%', height: '100%', gridGap: '0 0'}}>
                <HalfCardSection
                    title='Creche & Sunday School'
                    image={ChildrenImage}
                >
                    <p></p><br/>
                    <p>Our Children’s Church ministry offers children in our church family the opportunity to learn about Christ and scriptures, through practical and interactive engagements and conversations.</p> 
                <p>We believe that creative learning using a variety of resources that are relevant to our children’s daily lives enhances their ability to learn about God and engage with scripture.</p>
                <p>We have a number of activities that also take place during the year, which enable our children to engage with the wider church family using specific areas of interest
                 to them. Our children’s ministry leaders have all undergone safeguarding training and endeavour to the best of their ability 
                 to always put the children’s safety and welfare first.</p>
                </HalfCardSection>

                <HalfCardSection
                    title='Youth Worship Band'
                    image={YouthBand}
                    left={false}
                >
                    <p>(Ages xxx - xxx) </p><br/>
                    <h4><b></b></h4>
                    <p>Youth worship band description</p>
                </HalfCardSection>

                <HalfCardSection
                    title='Youth Ministry'
                    image={Globe}
                >
                    <p>(Ages xxx - xxx) </p><br/>
                    <h4><b></b></h4>
                    <p>Youth ministry description description</p>
                </HalfCardSection>

                <HalfCardSection
                    title='Vertical Youth Club'
                    image={ActivitiesImage}
                    left={false}
                >
                    <p>(Ages 13 - 17) </p><br/>
                    <h4><b></b></h4>
                    <p>Vertical Youth Club description</p>
                </HalfCardSection>
            </div> */}

        

            <BannerSection banners={banners} />


        </div>
    )
}

export default Homepage